body {
  margin: 0;
  padding: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  background-color: #f8f8f8;
}

.react-grid-item:not(.react-grid-placeholder) {
  border: 1px solid #ccc;
  background-color: #fff;
}

.react-grid-item.react-grid-placeholder {
  background: orange;
}

.responsive-container {
  overflow: hidden;
}

.background-graphics {
  opacity: 0.1;
}
